
























import { Component, Vue } from 'vue-property-decorator';
import { ActivityPaginator } from '@/typescript/types';
import UserAvatar from '@/components/user/UserAvatar.vue';
import ActivityCard from "@/components/board/utilities/ActivityCard.vue";
import {ActivitiesForSubjectsQuery, ActivityUpdatedSubscription} from "@/graphql/activity";

@Component({
  components: {ActivityCard, UserAvatar },

  apollo: {
    activitiesForSubjects: {
      query: ActivitiesForSubjectsQuery,
      variables() {
        return {
          ids: this.activityIds
        }
      },
      subscribeToMore: {
        document: ActivityUpdatedSubscription,
        variables() {
          return {
            id: this.activityIds
          }
        },
        updateQuery(previous, { subscriptionData }) {
          this.newCount++;
          return {
            activitiesForSubjects: {
              data: [subscriptionData.data.activityUpdated, ...previous.activitiesForSubjects.data],
              paginatorInfo: previous.activitiesForSubjects.paginatorInfo,
              __typename: previous.activitiesForSubjects.__typename,
            },
          };
        },
      },
    }
  }
})
export default class BoardActivity extends Vue {
  activitiesForSubjects: ActivityPaginator | null = null;
  page = 1;
  newCount = 0;

  get hasMorePages() {
    if (this.activitiesForSubjects) {
      return this.activitiesForSubjects.paginatorInfo.hasMorePages;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  get activityIds() {
    let idArray = [this.board.id];
    for(let i=0; i < this.board.objectives.length; i++) {
      idArray.push(this.board.objectives[i].id);
      for(let j=0; j < this.board.objectives[i].key_results.data.length; j++) {
        idArray.push(this.board.objectives[i].key_results.data[j].id);
      }
    }
    return idArray;
  }

  changePage() {
    this.page++;
    this.activitiesForSubjects!.data.splice(this.activitiesForSubjects!.data.length - this.newCount, this.newCount);
    this.newCount = 0;
    this.$apollo.queries.activitiesForSubjects.fetchMore({
      variables: {
        page: this.page,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          activitiesForSubjects: {
            __typename: previousResult.activitiesForSubjects.__typename,
            data: [...previousResult.activitiesForSubjects.data, ...fetchMoreResult.activitiesForSubjects.data],
            paginatorInfo: fetchMoreResult.activitiesForSubjects.paginatorInfo,
          },
        };
      },
    });
  }
}
