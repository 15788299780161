





























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Activity, ReactionType} from "@/typescript/types";
import {CreateReactionMutation} from "@/graphql/reaction";
import {formatDistance} from "date-fns";
import UserAvatar from "@/components/user/UserAvatar.vue";
import ActivityPreviewCard from "@/components/board/utilities/ActivityPreviewCard.vue";
import ReactionSummary from "@/components/board/utilities/ReactionSummary.vue";

@Component({
  components: {ReactionSummary, ActivityPreviewCard, UserAvatar}
})
export default class ActivityCard extends Vue {
  addReactionsActive = false;

  @Prop({ required: true })
  activity!: Activity;


  react(reaction: ReactionType) {
    this.addReactionsActive = false;
    this.$apollo
      .mutate({
        mutation: CreateReactionMutation,
        variables: {
          reactable_type: 'App\\Models\\Activity',
          reactable_id: this.activity.id,
          reaction: reaction
        },
      });
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  openReactionDetailed() {
    this.$buefy.modal.open({
      component: ReactionSummary,
      props: {
        reactions: this.activity.reactions,
      },
      width: '30vw',
      parent: this,
    });
  }
}
