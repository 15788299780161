










import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {  },

  props: {
    activityId: {},
    content: {}
  }

})
export default class ActivityPreviewCard extends Vue {

  get bgColor() {
    const colors = [
      "#FFC857",
      "#FF7E6B",
      "#87A878",
      "#A26769",
      "#998FC7",
      "#E86252",
    ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    return randomColor;
  }

  get bgPattern() {
    const pattern = [
      "https://i.ya-webdesign.com/images/geometric-pattern-png.png",
      "https://lh3.googleusercontent.com/proxy/cs3wggE2v7Z6EJatNbbfJvhd12bw_aBvBFVNIVx9R866lgoPMw9b92Vi9YV8xu8X38sg_VXtDtlbvHuRoTPpzJXDeFuJtQsRK4ekylE",
      "https://www.cmnsteel.com/wp-content/uploads/2018/01/geometric-cube-pattern-small.png",
    ];
    const randomPattern = pattern[Math.floor(Math.random() * pattern.length)];

    return randomPattern;
  }
}
