import gql from "graphql-tag";

export const ActivityTerseFramgment = gql`
    fragment ActivityTerse on Activity {
        id
        description
        created_at
        properties
        causer {
            ...on User {
                id
                name
                initials
                avatar
                deleted_at
            }
        }
        subject {
            ...on Board {
                id
                name
            }
            ...on Objective {
                id
                name
            }
            ...on KeyResult {
                id
                description
                objective {
                    name
                }
            }
        }
        reactionsCount
        reactions {
            reaction
            reacter {
                ...on User {
                    id
                    name
                    initials
                    avatar
                    deleted_at
                }
            }
            created_at
        }
        reactionSummaries {
            reaction
            count
        }
    }
`;

export const ActivitiesForSubjectsQuery = gql`
    query ActivitiesForSubjects(
        $ids: [ID!]!
        $first: Int
        $page: Int
    ) {
        activitiesForSubjects(ids: $ids, first: $first, page: $page) {
            data {
                ...ActivityTerse
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${ActivityTerseFramgment}
`;

export const ActivityUpdatedSubscription = gql`
    subscription ActivityUpdated($id: [ID!]!) {
        activityUpdated(id: $id) {
            ...ActivityTerse
        }
    }
    ${ActivityTerseFramgment}
`;
