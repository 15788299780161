

































import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from "@/components/user/UserAvatar.vue";

@Component({
  components: {UserAvatar  },
  props: {
    reactions: {}
  }

})
export default class ReactionSummary extends Vue {

}
